import './styles.scss';

////////// Bootstrap START

// Whole Bootstrap
// import * as bootstrap from 'bootstrap'

// import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
import 'bootstrap/js/dist/carousel';
import Collapse from 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
// import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/offcanvas';
// import 'bootstrap/js/dist/popover';
import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';

////////// Bootstrap END

import 'lazysizes'

document.addEventListener('DOMContentLoaded', function () {
    const navLinks = document.querySelectorAll('.moto-nav-link-with-collapse-on-click')
    const menuToggle = document.getElementById('navbarSupportedContent')
    const bsCollapse = Collapse.getOrCreateInstance(menuToggle, {toggle: false})
    navLinks.forEach((l) => {
        l.addEventListener('click', () => {
            bsCollapse.hide()
        })
    })
});